import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
export default {
  __name: 'emailDiv',
  setup(__props) {
    const email = ref("liuz@jodell.cn");
    const toEmail = () => {
      var foxmailEmail = `mailto:${email}`;
      window.open(foxmailEmail, "_blank");
      // window.location.href = foxmailEmail;
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "contentEmail",
        onClick: _cache[0] || (_cache[0] = $event => toEmail())
      }, _toDisplayString(email.value), 1);
    };
  }
};