import "core-js/modules/es.array.push.js";
import { detectZoom } from "./util/detectZoom";
import request from "./util/request.js";
import { useRoute, useRouter } from "vue-router";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: "app",
  data() {
    return {
      language: sessionStorage.getItem("language"),
      serviceShow: false,
      formData: {
        name: "",
        email: "",
        phone: "",
        consultingValue: ""
      }
    };
  },
  created() {},
  mounted() {
    setInterval(() => {
      if (window.location.href.includes("sales@jodell.cn")) {
        window.history.back();
      } else if (window.location.href.includes("liuz@jodell.cn")) {
        window.history.back();
      }
    });
    if (!sessionStorage.getItem("language")) {
      request({
        url: "/jodell/front/ip",
        method: "get"
      }).then(res => {
        if (res == "中国") {
          sessionStorage.setItem("language", "cn");
          document.title = "钧舵机器人Jodell Robotics";
        } else if (res == "日本") {
          sessionStorage.setItem("language", "jp");
          document.title = "钧舵机器人Jodell Robotics";
        } else if (res == "韩国") {
          sessionStorage.setItem("language", "kr");
          document.title = "钧舵机器人Jodell Robotics";
        } else {
          sessionStorage.setItem("language", "en");
          document.title = "Electric gripper - JODELL Robotics provides the most competitive executive components for industry automation";
        }
      }).catch(res => {
        sessionStorage.setItem("language", "en");
        document.title = "Electric gripper - JODELL Robotics provides the most competitive executive components for industry automation";
      }).finally(res => {
        this.$router.go(0);
      });
    } else {
      if (sessionStorage.getItem("language") == "en") {
        document.title = "Electric gripper - JODELL Robotics provides the most competitive executive components for industry automation";
      } else {
        document.title = "钧舵机器人Jodell Robotics";
      }
    }
    if (document.documentElement.clientWidth >= 1920) {
      const m = detectZoom();
      if (window.screen.width * window.devicePixelRatio >= 3840) {
        document.querySelector("#app").style.zoom = 100 / (Number(m) / 2);
      } else {
        if (window.screen.width > 1920) {
          const num = window.screen.width * window.devicePixelRatio / 1920;
          document.querySelector("#app").style.zoom = 100 / (Number(m) / num);
        } else {
          document.querySelector("#app").style.zoom = 100 / Number(m);
        }
      }
      //zoom属性用于设置或检索对象的缩放比例。
    } else {
      document.querySelector("#app").style.zoom = "";
    }
    window.addEventListener("resize", () => {
      if (document.documentElement.clientWidth >= 1920) {
        const m = detectZoom();
        if (window.screen.width * window.devicePixelRatio >= 3840) {
          document.querySelector("#app").style.zoom = 100 / (Number(m) / 2);
        } else {
          if (window.screen.width > 1920) {
            const num = window.screen.width * window.devicePixelRatio / 1920;
            document.querySelector("#app").style.zoom = 100 / (Number(m) / num);
          } else {
            document.querySelector("#app").style.zoom = 100 / Number(m);
          }
        }
      } else {
        document.querySelector("#app").style.zoom = "";
      }
    });
  },
  methods: {
    async submit() {
      if (!this.formData.name) {
        let name = this.getname().name;
        ElMessage.error(name);
        return false;
      }
      if (!this.formData.email) {
        let email = this.getname().email;
        ElMessage.error(email);
        return false;
      }
      if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/.test(this.formData.email)) {
        ElMessage.error(this.getname().emailRules);
        return false;
      }
      if (!this.formData.consultingValue) {
        let consultingValue = this.getname().consultingValue;
        ElMessage.error(consultingValue);
        return false;
      }
      await request({
        url: "/jodell/consulting/saveConsulting",
        method: "post",
        data: this.formData
      });
      ElMessage.success(this.getname().successMessage);
      this.$router.push({
        name: "thanks"
      });
      this.close();
      this.formData = {
        name: "",
        unitName: "",
        email: "",
        phone: "",
        consultingValue: ""
      };
    },
    serviceClick() {
      this.serviceShow = true;
    },
    close() {
      this.serviceShow = false;
      this.formData = {
        name: "",
        unitName: "",
        email: "",
        phone: "",
        consultingValue: ""
      };
    },
    clickImg() {
      let res = document.querySelector("#yzm");
      res.src = "/jodell/consulting/getCaptcha?time=" + Date.now();
    },
    getname() {
      let list = {
        name: "输入您的姓名",
        email: "输入您的邮箱",
        phone: "输入您的手机号",
        consultingValue: "输入您的需求",
        area: "输入您的地区",
        submit: "提交信息",
        rules: "请输入正确的手机号",
        successMessage: "提交成功",
        emailRules: "请输入正确的邮箱"
      };
      switch (this.language) {
        case "cn":
          list = {
            name: "输入您的姓名",
            email: "输入您的邮箱",
            phone: "输入您的手机号",
            consultingValue: "输入您的需求",
            submit: "提交信息",
            area: "输入您的地区",
            rules: "请输入正确的手机号",
            successMessage: "提交成功",
            emailRules: "请输入正确的邮箱"
          };
          break;
        case "en":
          list = {
            name: "Name",
            email: "Email",
            phone: "Phone number",
            consultingValue: "Say someting to us ,and we will get back to you soon",
            submit: "submit information",
            area: "Where are you",
            successMessage: "Submitted successfully",
            emailRules: "Please enter the correct email address"
          };
          break;
        case "jp":
          list = {
            name: "名前を入力",
            email: "メールボックスを入力",
            phone: "携帯番号を入力",
            consultingValue: "ニーズの入力",
            submit: "コミット情報",
            area: "地区",
            rules: "正しい携帯番号を入力してください",
            successMessage: "コミット成功",
            emailRules: "正しいメールアドレスを入力してください"
          };
          break;
        case "kr":
          list = {
            name: "이름 입력",
            email: "메일박스 입력",
            phone: "휴대폰 번호 입력",
            consultingValue: "요구 사항 입력",
            submit: "정보 제출",
            area: "地区",
            rules: "정확한 핸드폰 번호를 입력하세요.",
            successMessage: "커밋 성공",
            emailRules: "올바른 메일박스를 입력하십시오."
          };
          break;
        default:
          break;
      }
      return list;
    }
  }
};