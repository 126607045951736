import "core-js/modules/es.array.push.js";
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/mobile/menu.png';
import _imports_1 from '../assets/mobile/closeIcon.png';
const _withScopeId = n => (_pushScopeId("data-v-a6e17254"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "header"
};
const _hoisted_2 = {
  class: "header-content"
};
const _hoisted_3 = {
  style: {
    "min-width": "220px"
  }
};
const _hoisted_4 = {
  class: "menu-list"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["onMouseenter", "onMouseleave"];
const _hoisted_7 = {
  class: "menu-botton"
};
const _hoisted_8 = {
  key: 1,
  class: "headerMb"
};
const _hoisted_9 = {
  class: "topMb"
};
const _hoisted_10 = {
  key: 0,
  class: "menuListMb"
};
const _hoisted_11 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _directive_img_lazy = _resolveDirective("img-lazy");
  return $setup.ispc ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("img", {
    class: "logo",
    alt: "logo",
    onClick: _cache[0] || (_cache[0] = $event => $setup.chooseNav('index'))
  }, null, 512), [[_directive_img_lazy, $setup.language == 'en' ? $setup.logoImgEn : $setup.logoImg]])]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navlistPc, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "nav",
      onClick: $event => $setup.chooseNav(item.navPath)
    }, [_createElementVNode("div", {
      class: _normalizeClass({
        'nav-name-choose': $setup.navChoose === item.navPath
      }),
      onMouseenter: $event => item.navChildShow = true,
      onMouseleave: $event => item.navChildShow = false
    }, [_createElementVNode("span", {
      class: _normalizeClass(["nav-name", {
        'nav-name-en': $setup.language == 'en' || $setup.language == 'jp'
      }])
    }, _toDisplayString(item.navName), 3)], 42, _hoisted_6)], 8, _hoisted_5);
  }), 128))]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_select, {
    modelValue: $setup.language,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.language = $event),
    style: {
      "width": "105px",
      "margin-right": "16px"
    },
    onChange: $setup.changeLanguage
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.value,
        label: item.name,
        value: item.value
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"]), $setup.language == 'cn' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "contact-us",
    onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push({
      name: 'contact-index'
    }))
  }, " 联系我们 ")) : _createCommentVNode("", true), $setup.language == 'en' ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "contact-us",
    onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push({
      name: 'contact-index'
    }))
  }, " Contact us ")) : _createCommentVNode("", true), $setup.language == 'jp' ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: "contact-us",
    onClick: _cache[4] || (_cache[4] = $event => _ctx.$router.push({
      name: 'contact-index'
    }))
  }, " お問い合わせ ")) : _createCommentVNode("", true), $setup.language == 'kr' ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    class: "contact-us",
    onClick: _cache[5] || (_cache[5] = $event => _ctx.$router.push({
      name: 'contact-index'
    }))
  }, " 문의하기 ")) : _createCommentVNode("", true)])])])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_withDirectives(_createElementVNode("img", {
    alt: "图片",
    class: "logoMb",
    onClick: _cache[6] || (_cache[6] = $event => $setup.chooseNav('index'))
  }, null, 512), [[_directive_img_lazy, $setup.language == 'en' ? $setup.logoImgEn : $setup.logoImg]]), _createElementVNode("div", null, [_createVNode(_component_el_select, {
    modelValue: $setup.language,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $setup.language = $event),
    size: "small",
    style: {
      "width": "80px",
      "margin-right": "10px"
    },
    onChange: $setup.changeLanguage
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.value,
        label: item.name,
        value: item.value
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"]), !$setup.menuShow ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _imports_0,
    alt: "图片",
    class: "menuMb",
    onClick: _cache[8] || (_cache[8] = $event => $setup.menuShow = !$setup.menuShow)
  })) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _imports_1,
    alt: "图片",
    class: "menuMbClose",
    onClick: _cache[9] || (_cache[9] = $event => $setup.menuShow = !$setup.menuShow)
  }))])]), _createVNode(_Transition, {
    name: "el-fade-in-linear"
  }, {
    default: _withCtx(() => [$setup.menuShow ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navlistMb, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "navMb",
        key: index,
        onClick: $event => $setup.chooseNav(item.navPath),
        style: _normalizeStyle({
          color: _ctx.$route.path == '/' + item.navPath || _ctx.$route.path == '/' + item.navChildPath || _ctx.$route.path == '/' && item.navPath == 'index' ? '#185097' : 'black'
        })
      }, _toDisplayString(item.navName), 13, _hoisted_11);
    }), 128))])) : _createCommentVNode("", true)]),
    _: 1
  })]));
}