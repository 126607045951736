import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import BackHome from "../views/admin/Home.vue";

let language = sessionStorage.getItem("language");
/* if (!language) {
  sessionStorage.getItem("language", "cn");
} */
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      // 首页
      {
        path: "",
        name: "index",
        component: () =>
          import(
            `../views/index${language == "cn" ? "" : "-" + language}/Index.vue`
          ),
      },
      // 产品中心首页
      {
        path: "product-index",
        name: "product-index",
        component: () =>
          import(
            `../views/product-center${
              language == "cn" ? "" : "-" + language
            }/Index.vue`
          ),
      },
      {
        path: "product-detail",
        name: "product-detail",
        component: () =>
          import(
            `../views/product-center${
              language == "cn" ? "" : "-" + language
            }/Detail.vue`
          ),
      },
      /*  {
        path: "/prize-get",
        name: "prize-get",
        component: () =>
          import(
            `../views/product-center${
              language == "cn" ? "" : "-" + language
            }/PrizeGet.vue"`
          ),
      }, */
      // 解决方案首页
      {
        path: "/resolve-index",
        name: "resolve-index",
        component: () =>
          import(
            `../views/resolve-plan${
              language == "cn" ? "" : "-" + language
            }/Index.vue`
          ),
      },
      {
        path: "/resolve-detail",
        name: "resolve-detail",
        component: () =>
          import(
            `../views/resolve-plan${
              language == "cn" ? "" : "-" + language
            }/Detail.vue`
          ),
      },
      // 公司简介
      {
        path: "/company-introduce",
        name: "company-introduce",
        component: () =>
          import(
            `../views/company-introduce${
              language == "cn" ? "" : "-" + language
            }/Index.vue`
          ),
      },
      // 联系我们
      {
        path: "/contact-index",
        name: "contact-index",
        component: () =>
          import(
            `../views/contact-us${
              language == "cn" ? "" : "-" + language
            }/Index.vue`
          ),
      },
      {
        path: "/join-us",
        name: "join-us",
        component: () =>
          import(
            `../views/contact-us${
              language == "cn" ? "" : "-" + language
            }/JoinUs.vue`
          ),
      },
      // 下载中心
      {
        path: "/dowmload-center",
        name: "dowmload-center",
        component: () =>
          import(
            `../views/dowmload-center${
              language == "cn" ? "" : "-" + language
            }/Index.vue`
          ),
      },
      // 动态中心
      {
        path: "/new-center",
        name: "new-center",
        component: () =>
          import(
            `../views/new-center${
              language == "cn" ? "" : "-" + language
            }/Index.vue`
          ),
      },
      {
        path: "/new",
        name: "new",
        component: () =>
          import(
            `../views/new-center${
              language == "cn" ? "" : "-" + language
            }/New.vue`
          ),
      },
      // 诚招代理
      {
        path: "/agent-wanted",
        name: "agent-wanted",
        component: () =>
          import(
            `../views/agent-wanted${
              language == "cn" ? "" : "-" + language
            }/Index.vue`
          ),
      },
      //钧舵生态
      {
        path: "/jodell-ecology",
        name: "jodell-ecology",
        component: () =>
          import(
            `../views/JodellEcology${
              language == "cn" ? "" : "-" + language
            }/index.vue`
          ),
      },
      {
        path: "/jodell-ecology-detail",
        name: "jodell-ecology-detail",
        component: () =>
          import(
            `../views/JodellEcology${
              language == "cn" ? "" : "-" + language
            }/detail.vue`
          ),
      },
       // 感谢页
       {
        path: "thanks",
        name: "thanks",
        component: () =>
          import(
            `../views/thanks/index.vue`
          ),
      },
    ],
  },

  // 后台管理系统
  {
    path: "/login",
    name: "login",
    component: () => import("../views/admin/Login.vue"),
  },
  {
    path: "/back-home/",
    name: "back-home",
    component: BackHome,
    children: [
      {
        path: "",
        name: "back-index",
        component: () => import("../views/admin/Index.vue"),
      },
      {
        path: "product-manage",
        name: "product-manage",
        component: () => import("../views/admin/ProductManage.vue"),
      },
      {
        path: "product-create",
        name: "product-create",
        component: () => import("../views/admin/ProductCreate.vue"),
      },
      {
        path: "new-list",
        name: "back-new-list",
        component: () => import("../views/admin/NewList.vue"),
      },
      {
        path: "new-create",
        name: "back-new-create",
        component: () => import("../views/admin/NewCreate.vue"),
      },
      {
        path: "prize-consult",
        name: "prize-consult",
        component: () => import("../views/admin/PrizeConsult.vue"),
      },
      {
        path: "resource-manage",
        name: "resource-manage",
        component: () => import("../views/admin/ResourceManage.vue"),
      },
      {
        path: "vip-manage",
        name: "vip-manage",
        component: () => import("../views/admin/VipManage.vue"),
      },
    ],
  },
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
