import axios from "axios";
import {
  ElMessage
} from "element-plus";
import router from "../router";
import {
  setToken,
  getToken
} from "./token";
let language = sessionStorage.getItem("language");
/* if (!language) {
  sessionStorage.setItem("language", "cn");
} */
const service = axios.create({
  baseURL: "",
  headers: {
    get: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      "X-Requested-With": "XMLHttpRequest",
      Language: language,
    },
    post: {
      "Content-Type": "application/json;charset=utf-8",
      Accept: "application/json",
      Language: language,
    },
  },
  timeout: 10000,
  transformRequest: [
    (data) => {
      data = JSON.stringify(data);
      return data;
    },
  ],
});

let message = '服务器错误，请稍后再试'
switch (language) {
  case 'ch':
    message = '服务器错误，请稍后再试'
    break;
  case 'en':
    message = 'Server error, please try again later'
    break;
  case 'jp':
    message = 'サーバーエラー、後で再試行してください'
    break;
  case 'kr':
    message = '서버 오류, 나중에 다시 시도하십시오.'
    break;
  default:
    break;
}

service.interceptors.request.use(
  (config) => {
    // set token to authorization
    config.headers["authorization"] = getToken();
    return config;
  },
  (error) => {
    // output error
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response) => {
      // judge response state
      const res = response.data;
      if (res.code !== "10000") {
        if (res.code === "A0312") {
          ElMessage({
            message: "登录超时，请重新登录",
            type: "error"
          });
          router.push("/login");
        } else {
          // response error message tip
          // ElMessage({ message: res.message || 'Error', type: 'error' })
        }
        ElMessage({
          message: message,
          type: "error"
        });
        return Promise.reject(message);
      } else {
        // set token
        const token = response.headers.authorization;
        if (token) {
          setToken(token);
        }
        return res.data;
      }
    },
    (error) => {
      // output error
      ElMessage({
        message: message,
        type: "error"
      });
      return Promise.reject(error);
    }
);

export default service;